namespace $ {
    export const $mol_lang_iso639 = {
        ab: "Abkhazian",
        aa: "Afar",
        af: "Afrikaans",
        ak: "Akan",
        sq: "Albanian",
        am: "Amharic",
        ar: "Arabic",
        an: "Aragonese",
        hy: "Armenian",
        as: "Assamese",
        av: "Avaric",
        ae: "Avestan",
        ay: "Aymara",
        az: "Azerbaijani",
        bm: "Bambara",
        ba: "Bashkir",
        eu: "Basque",
        be: "Belarusian",
        bn: "Bengali",
        bi: "Bislama",
        nb: "Bokmål",
        bs: "Bosnian",
        br: "Breton",
        bg: "Bulgarian",
        my: "Burmese",
        ca: "Catalan",
        ch: "Chamorro",
        ce: "Chechen",
        zh: "Chinese",
        cu: "Church Slavonic",
        cv: "Chuvash",
        kw: "Cornish",
        co: "Corsican",
        cr: "Cree",
        hr: "Croatian",
        cs: "Czech",
        da: "Danish",
        dv: "Divehi",
        nl: "Dutch",
        dz: "Dzongkha",
        en: "English",
        eo: "Esperanto",
        et: "Estonian",
        ee: "Ewe",
        fo: "Faroese",
        fj: "Fijian",
        fi: "Finnish",
        fr: "French",
        fy: "Frisian",
        ff: "Fulah",
        gd: "Gaelic",
        gl: "Galician",
        lg: "Ganda",
        ka: "Georgian",
        de: "German",
        el: "Greek",
        gn: "Guarani",
        gu: "Gujarati",
        ht: "Haitian",
        ha: "Hausa",
        he: "Hebrew",
        hz: "Herero",
        hi: "Hindi",
        ho: "Hiri Motu",
        hu: "Hungarian",
        is: "Icelandic",
        io: "Ido",
        ig: "Igbo",
        id: "Indonesian",
        ia: "InterlinguA",
        ie: "InterlinguE",
        iu: "Inuktitut",
        ik: "Inupiaq",
        ga: "Irish",
        it: "Italian",
        ja: "Japanese",
        jv: "Javanese",
        kl: "Kalaallisut",
        kn: "Kannada",
        kr: "Kanuri",
        ks: "Kashmiri",
        kk: "Kazakh",
        km: "Khmer",
        ki: "Kikuyu",
        rw: "Kinyarwanda",
        ky: "Kyrgyz",
        kv: "Komi",
        kg: "Kongo",
        ko: "Korean",
        kj: "Kwanyama",
        ku: "Kurdish",
        lo: "Lao",
        la: "Latin",
        lv: "Latvian",
        li: "Limburgan",
        ln: "Lingala",
        lt: "Lithuanian",
        lu: "Luba-Katanga",
        lb: "Luxembourgish",
        mk: "Macedonian",
        mg: "Malagasy",
        ms: "Malay",
        ml: "Malayalam",
        mt: "Maltese",
        gv: "Manx",
        mi: "Maori",
        mr: "Marathi",
        mh: "Marshallese",
        mn: "Mongolian",
        na: "Nauru",
        nv: "Navaho",
        nd: "North Ndebele",
        ng: "Ndonga",
        ne: "Nepali",
        no: "Norwegian",
        ny: "Nyanja",
        nn: "Nynorsk",
        oc: "Occitan",
        oj: "Ojibwa",
        or: "Oriya",
        om: "Oromo",
        os: "Ossetian",
        pi: "Pali",
        ps: "Pushto",
        fa: "Persian",
        pl: "Polish",
        pt: "Portuguese",
        pa: "Panjabi",
        qu: "Quechua",
        ro: "Romanian",
        rm: "Romansh",
        rn: "Rundi",
        ru: "Russian",
        se: "Sami",
        sm: "Samoan",
        sg: "Sango",
        sa: "Sanskrit",
        sc: "Sardinian",
        sr: "Serbian",
        sn: "Shona",
        ii: "Sichuan Yi",
        sd: "Sindhi",
        si: "Sinhala",
        sk: "Slovak",
        sl: "Slovenian",
        so: "Somali",
        st: "Sotho",
        nr: "South Ndebele",
        es: "Spanish",
        su: "Sundanese",
        sw: "Swahili",
        ss: "Swati",
        sv: "Swedish",
        tl: "Tagalog",
        ty: "Tahitian",
        tg: "Tajik",
        ta: "Tamil",
        tt: "Tatar",
        te: "Telugu",
        th: "Thai",
        bo: "Tibetan",
        ti: "Tigrinya",
        to: "Tonga",
        ts: "Tsonga",
        tn: "Tswana",
        tr: "Turkish",
        tk: "Turkmen",
        tw: "Twi",
        ug: "Uyghur",
        uk: "Ukrainian",
        ur: "Urdu",
        uz: "Uzbek",
        ve: "Venda",
        vi: "Vietnamese",
        vo: "Volapük",
        wa: "Walloon",
        cy: "Welsh",
        wo: "Wolof",
        xh: "Xhosa",
        yi: "Yiddish",
        yo: "Yoruba",
        za: "Zhuang",
        zu: "Zulu",
    }
}
